/*Main Recorder*/
.VideoRecorderStyled{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  font-family: Poppins, san-serif, serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .videoHeader {
    position: absolute;
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    justify-content: flex-start;

    h2 {
      color: white;
      font-size: 23px;
      font-weight: bold;
      font-family: Poppins, san-serif, serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 0;
      text-shadow: 2px 2px 50px #000;
    }

    h3 {
      color: white;
      font-size: 13px;
      width: 20rem;
      white-space: break-spaces;
      margin: 0;
      font-family: Poppins, san-serif, serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-shadow: 2px 2px 10px #000;


      svg {
        margin-right: 6px;
        cursor: pointer;
      }

      div, span {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  video {
    background: black;
    height: 300px !important;
    width: 400px !important;
    object-fit: cover;
  }

  .videoPreview {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }

  .videoFooter {
    position: absolute;
    width: 100%;
    z-index: 20;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .actionButton {
      width: 50px;
      cursor: pointer;
      height: 50px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      background: #0062ff;
      align-items: center;
      overflow: hidden;

      span {
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .counter{
        font-size: 11px;
        padding-top: 2px;
      }
    }

    .retryIcon {
      text-transform: capitalize;
      svg {
        margin-right: 4px;
      }
    }
  }

  .recording {
    display: flex;
    svg {
      margin-right: 3px;
    }
  }

  .ZFLoading {
    background: #0062ff44 !important;
  }

  .darkOverlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 23%, rgba(0, 0, 0, 0));
    position: absolute;
    z-index: 10;
    border-radius: 8px;
  }
}


/*Modal*/
.videoModal {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-paper {
    outline: 0;
    color: black;
    width: 800px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 500px 200px rgba(0, 0, 0, 0.05);
    position: relative;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #d4d4d4;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #d4d4d4;
    }

    .modal-header {
      height: 60px;
      border: none;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      z-index: 100;
      right: 8px;
      top: 8px;
      svg {
        fill: #ffffff;
        opacity: 1;
        font-size: 2rem;
        :hover{
          opacity: 0.75;
        }
      }
    }

    .paper-content {

      .modal-title {
        font-size: 23px;
        font-weight: 800;
      }

      .modal-text {
        font-weight: 400;
        color: #333;
        margin-bottom: 31px;
      }

      .modal-body {
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .modalInput {
          width: 60%;
          padding-right: 30px;
          box-shadow: 26px 0 0px -25px #eeeeee;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .paper-content {
      margin: 0 20px !important;
      padding: 0 0px 21px !important;
    }

    .modalInput {
      width: 100% !important;
      padding-right: 0 !important;
      box-shadow: none !important;
    }
  }
}


/*Trigger Button */
.triggerButton{
  .videoActionBtn {
    background-color: transparent;
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    color: #676a6c;
    padding: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    position: relative;

    &:hover {
      color: #1A91EB;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: currentColor;

      &:hover {
        color: #1A91EB;
      }
    }

    .betaIcon {
      position: absolute;
      font-size: 8px;
      top: -5px;
      right: 3px;
    }
  }
  &.drop-options-list-item {
    .videoActionBtn {
      width: 52px;

      &:hover {
        color: currentColor;
      }

      .betaIcon {
        font-size: 8px;
        top: 3px;
        color: black;
        right: -37px;
        border: 1px solid black;
        display: flex;
        padding: 3px;
        align-items: center;
        line-height: 9px;
        border-radius: 3px;
      }

      svg {
        width: 25px;
        height: 25px;
      }

      div {
        padding-left: 3px;
      }
    }
  }
}
