.workout-details-container {
  font-size: 15px;
  max-height: calc(100% - 63px);
  overflow-y: auto;
  padding: 0 16px;
}
.workout-action-button {
  background: #fff;
  border: solid 1px #007aff;
  border-radius: 4px;
  color: #1a91eb;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  &.dark-button {
    color: #000000;
    border: solid 1px #c8c7cc;
  }
}
.workout-details-video {
  margin-bottom: 15px;
  iframe,
  video {
    border-radius: 4px;
  }
}
.workout-details-stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 17px;
  .workout-details-stat-wrapper {
    width: 50%;
    &:nth-child(odd) {
      .workout-details-stat {
        margin-right: 8px;
      }
    }
    &:nth-child(even) {
      .workout-details-stat {
        margin-left: 8px;
      }
    }
    &:nth-last-child(2),
    &:last-child {
      .workout-details-stat {
        border-bottom: 1px solid #e6eaee;
      }
    }
  }
  .workout-details-stat {
    align-items: center;
    border-top: 1px solid #e6eaee;
    color: #1a91eb;
    display: flex;
    padding: 12px 0;
    h4 {
      color: #000000;
      flex: 1;
      font-weight: 500;
      margin: 0;
    }
    button {
      background: #fff;
      border: none;
      min-width: 30px;
      line-height: 1.47;
      padding: 0;
      text-align: right;
      color: inherit;
      font-size: inherit;
    }
    input {
      border: none;
      outline: none;
      padding: 0;
      text-align: right;
      width: 85px;
      color: inherit;
      font-size: inherit;
    }
  }
}
.workout-details-comment {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  line-height: 1.4;
  margin-bottom: 16px;
  min-height: 68px;
  padding: 8px 16px;
  resize: none;
  width: 100%;
  &::placeholder {
    color: #a8aab7;
    font-weight: 500;
  }
}
.workout-details-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  button {
    padding: 8px 5px;
    min-width: 166px;
  }
}
.workout-details-tracking {
  text-align: center;
  h4 {
    color: #354052;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 0 16px;
  }
  .tracking-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    button {
      padding: 8px 12px;
    }
  }
}
.workout-details-remove-btn {
  color: #ff2d55;
  background: #fff;
  border: none;
  outline: none;
  padding: 14px 52px;
  width: 100%;
}
