.track-weight-container {
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 63px);
  overflow-y: auto;
  padding-top: 25px;
  button {
    background: none;
    border: none;
    color: #1a91eb;
    font-size: 17px;
    padding: 0;
  }
  .track-date {
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    margin-bottom: 32px;
    min-height: 50px;
    padding: 12px 15px;
    p {
      align-self: center;
      color: #354052;
      flex: 1;
      font-size: 15px;
      margin: 0;
    }
    button {
      text-align: right;
    }
  }
  .track-content {
    flex: 1;
  }
  table {
    width: 100%;
    thead {
      color: #7f8fa4;
      font-size: 13px;
      line-height: 1.38;
    }
    tbody {
      background: #ffffff;
      border-top: 1px solid #c8c7cc;
      font-size: 17px;
      line-height: 1.29;
    }
    tr {
      border: none;
      th {
        font-weight: 400;
        text-align: right;
        padding-bottom: 5px;
        width: 35%;
      }
      td {
        border: none;
        color: #1a91eb;
        padding: 0;
        text-align: right;
        vertical-align: bottom;
        .wrapper {
          border-bottom: 1px solid #c8c7cc;
          padding: 11px 0;
        }
        .wrapper-flex {
          display: flex;
        }
        input {
          border: none;
          outline: none;
          padding: 0 4px 0 0;
          text-align: right;
          width: 100%;
          font-size: inherit;
          color: inherit;
        }
      }
      th:first-child,
      td:first-child {
        padding-left: 16px;
        text-align: left;
        width: 19%;
      }
      td:first-child {
        color: #354052;
      }
      th:last-child,
      td:last-child {
        text-align: center;
        width: auto;
        i {
          color: #7f8fa4;
          font-size: 18px;
          vertical-align: top;
        }
      }
    }
  }
  .track-add-btn {
    background: #ffffff;
    padding: 11px 16px 7px;
    border-bottom: 1px solid #c8c7cc;
  }
  .track-history-btn {
    padding: 24px 0;
    text-align: center;
  }
}
