$light-bg-color: #fafafb;
.chat-message-url {
  color: white;
  &:hover {
    color: white;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.chat-camera {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.05) 0 0 0 1px, rgba(50, 50, 93, 0.1) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  max-width: 712px;
  position: relative;
  overflow: hidden;

  &-preview {
    background-color: #000;

    &::before {
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  &-body {
    padding: 32px;
    text-align: center;
  }

  &-title {
    color: #3b4351;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 8px;
  }

  &-caption {
    color: #676a6c;
    font-size: 14px;
    margin-bottom: 0;
  }

  &-header,
  &-footer {
    position: absolute;
    left: 0;
    right: 0;
    padding: 16px;
  }

  &-header {
    top: 0;
  }

  &-footer {
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-time {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    padding: 2px 8px;
    min-height: 18px;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: uppercase;

    &.--recording {
      background-color: #da1847;
    }
  }

  &-close {
    background: none;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 2px;
    }

    svg {
      display: block;
      width: 20px;
      height: 20px;

      path:nth-child(2) {
        fill: currentColor;
      }
    }
  }

  &-btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    min-height: 32px;
    border-radius: 3px;
    padding: 6px 12px;
    transition: all 0.15s ease 0s;
    outline: none;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    color: #fff;

    svg {
      display: block;
      width: 18px;
      height: 18px;

      path:nth-child(2) {
        fill: currentColor;
      }
    }

    &.disabled svg {
      opacity: 0.5;
    }

    & + .chat-camera-btn {
      margin-left: 8px;
    }

    &.--primary {
      background-color: #538fee;
      color: #fff;
    }

    &.--danger {
      background-color: #da1847;
      color: #fff;
    }

    &.--icon {
      padding: 0;
      width: 32px;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (min-width: 768px) {
    border-radius: 6px;
    margin: 1.75rem auto;
  }
}

.camera-btn-tooltip {
  & + .chat-camera-btn {
    margin-left: 8px;
  }
}
