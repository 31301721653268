.App {
  font-family: sans-serif;
  text-align: center;
}
.ghost {
  opacity: 0.5;
}

.chosen {
  background-color: aqua;
}
.dragdragdrag {
  display: flex;
  align-items: center;
  cursor: move;
}