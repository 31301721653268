.list-sortable {
  $move-handle: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAFtJREFUSA3tkbEJACAQA8XFHcEl3FOx+OaQ8IJYRXgkCaS4lOL3gkDrY+6LLurwT389mfauCZA5tSr0BopOPiNzatXkDRSdfEbm1KrJGyg6+YzMqVWTN1B0/mQLKEk130qvR2oAAAAASUVORK5CYII=");
  list-style: none;
  margin: 0;
  padding: 0;
  .sortable-item {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 8px 10px 8px 25px;
    position: relative;

    > .exercise-item-move {
      color: red;
      background: $move-handle no-repeat 50% 50%;
    }
  }
  .sortable-drag {
    position: relative;
    > .sortable-item-wrapper {
      background: #cdcdcd;
      border-radius: 2px;
      > .sortable-item {
        border: 1px solid #8dafd5;
        transform: rotate(1.5deg);
      }
    }
  }
}
