html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
  color: #676a6c !important;
  font-size: 14px !important;
}
