.exercises-list-search {
  background-color: #f6f7f7;
  box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
  padding: 7px 8px;
  input {
    background: #ffffff;
    border-radius: 5px;
    border: none;
    font-family: "Roboto", "Helvetica Neue", FontAwesome, Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 5px 0 5px 0;
    text-align: center;
    width: 100%;
    &::placeholder {
      color: #8c8c8c;
    }
  }
}
.exercises-list {
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  li {
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    border-bottom: 1px solid #e6eaee;
    margin: 0;
    padding: 10px 16px 10px 16px;
    text-align: left;
    .exercise-image {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      img {
        border-radius: 4px;
        width: 40px;
      }
    }
    .exercise-text {
      user-select: none;
      flex: 1;
      margin-left: 14px;
      h4 {
        color: #14181f;
        font-size: 13px;
        font-weight: 500;
        margin: 0 0 5px;
      }
      span {
        font-size: 13px;
        color: #7f8fa4;
      }
    }
    .exercise-status {
      color: #7f8fa4;
      font-size: 15px;
      line-height: 1.47;
      &.selected {
        color: #1a91eb;
      }
    }
  }
}
