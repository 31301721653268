.users-list-search {
  background: #f6f7f7;
  padding: 7px 8px;
  input {
    background: #ffffff;
    border-radius: 5px;
    border: none;
    font-family: "Roboto", "Helvetica Neue", FontAwesome, Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 5px 0 5px 0;
    text-align: center;
    width: 100%;
  }
}
.users-list {
  list-style: none;
  margin: 0;
  max-height: calc(100% - 169px);
  overflow-y: auto;
  padding: 0;
  li {
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    border-bottom: 1px solid #e6eaee;
    font-size: 15px;
    margin: 0;
    line-height: 1.47;
    padding: 10px 16px;
    .user-image img {
      border-radius: 50px;
      width: 40px;
    }
    .user-name {
      color: #14181f;
      flex: 1;
      font-weight: 500;
      padding-left: 14px;
      p {
        margin: 0;
      }
    }
    .user-status {
      color: #7f8fa4;
      &.selected {
        color: #1a91eb;
      }
    }
  }
}