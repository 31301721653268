.modal-container {
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 102;
  }
}