.modal-container {
  &.hidden {
    display: none !important;
  }

  .modal-wrapper {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 102;
  }
}

.options-list-wrapper {
  font-size: 16px;
  line-height: 19px;
  ul {
    color: #354052;
    margin: 0;
    list-style: none;
    overflow: hidden;
    padding: 0;
    li {
      background-color: #f7f9fb;
      padding: 16px 24px;
    }
    li:first-child {
      padding-top: 32px;
    }
  }
  .btn-cancel {
    background-color: #f7f9fb;
    border: none;
    color: #1a91eb;
    font-weight: bold;
    padding: 16px 24px 32px;
    text-align: left;
    width: 100%;
  }
}
