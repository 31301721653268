.plans-board {
  padding: 0 0 100px;
  flex: 1;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .intro-gif {
    max-width: 100%;
    border-radius: 2px;
  }

  .intro-handle {
    background: #fff;
    border-radius: 2px;
    margin: 10px;
  }

}

.servingsLabel{
  color: white!important;
  background: rgb(64, 142, 246);
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 600!important;
  font-size: 12px;
}

.plans-board-header {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(44, 64, 90, 0.2) 0px 1px 4px 0px;
  display: flex;
  align-items: center;
  border-left: 1px solid #dcdddd;
  //margin-bottom: 22px;
  padding: 12px 16px;
  //padding-right: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 500;

  .board-updated-at {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: right;
    color: #7f8fa4;
    margin-right: 20px;
    margin-top: 7px;
  }

  button {
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  }

  var {
    font-style: normal;
  }

  .board-btn-icon i {
    font-size: 10px;
  }


  .board-meal-totals {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h6 {
      color: #354052;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .board-meal-meta {
    font-size: 12px;
    font-weight: 500;
    color: #8898AA;
    margin-top: 4px;

    [data-color="red"] {
      color: #fe4844;
    }

    [data-color="yellow"] {
      color: #fd9240;
    }

    [data-color="blue"] {
      color: #00a2f3;
    }
  }

  .board-meal-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 4px;

    .btn {
      padding: 2px;
      text-shadow: none;
      background: transparent;
      border-radius: 0;
      border: 0;
      color: #8898AA;
      outline: none;

      &:hover {
        color: #2895f1;
      }

      &:active {
        background: transparent;
        box-shadow: none;
        color: #23527c;
        outline: none;
      }

      & + .btn {
        margin-left: 6px;
      }
    }
  }
}

.plans-board-header-left {
  flex: 1;

  h2 {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: left;
    color: #0f141b;
    margin: 0;

    .material-icons {
      font-size: 17px;
    }
  }

  small {
    color: #7F8FA4;
    display: block;
    font-weight: 600;
    font-size: 12px;
  }
}

.plans-board-header-right {
  flex-shrink: 1;
  display: inline-flex;
}

.plans-board-list {
  margin: 22px 10px 0;
}

.plans-temp {
  overflow: hidden;
  height: 0;
}

.plans-board-comment {
  margin: 5px 10px 0;
  padding: 10px 0;
}

@media (max-width: 1024px) {
  .plans-board-header {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }

  .plans-board-header-left {
    flex: 1 1 100%;
  }

  .plans-board-header-right {
    flex: 1 1 100%;
    margin-top: 10px;

    .btn {
      margin-bottom: 4px;
    }
  }
}
