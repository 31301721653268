#recipeModal.in {
  .modal-body {
    height: 60vh;
    overflow-y: scroll;
  }
  .modal-dialog {
    margin-top: 80px !important;
  }
  .meal-col {
    label {
      width: 100%;
      display: flex;
      color: #333;
      justify-content: space-between;
    }
  }

  .area {
    overflow: hidden;
  }

  .area .col {
    float: left;
    border: 1px solid #dedede;
    margin-bottom: 10px;
  }

  .area .col:first-child {
    border-radius: 3px 0 0 3px;
    margin-left: 0 !important;
  }

  .area .col:last-child {
    border-radius: 0 3px 3px 0;
  }

  .area label {
    font-weight: 500;
    color: #000;
    padding: 6px 16px;
  }

  .area input {
    display: none;
  }

  .meal-area {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .meal-area .meal-col {
    float: inherit;
    margin-top: 0;
  }

  .current {
    background-color: rgba(40, 149, 241, 0.1);
    border: solid 1px #1a91eb;
    color: #1a91eb !important;
    position: relative;
    z-index: inherit;
  }
}
